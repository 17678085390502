import React from 'react'
import * as style from '../styles/contentBox.module.css'

export default function GetInTouch() {
  return (
    <div className="center">
    <a href="https://forms.gle/Bq53PuQUG7n8KcYy8" target="_blank" rel="noreferrer" className={style.button}>
        GET IN TOUCH
    </a>
</div>
  )
}
