import * as React from "react"
import "../styles/global.css"
import Hero from "../components/Hero"
import Intro from "../components/Intro"
import History from "../components/History"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet"

// markup
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Phil Calzavara - Founder, Engineer & Data Scientist</title>
        <meta name="description" content="I am an Engineer and Data Scientist, founder of DreamStories.ai. I'm experienced in data stacks, AI models, and web development. Fluent in four languages, I bring a global perspective to data." />

        <meta property="og:title" content="Phil Calzavara - Founder, Engineer & Data Scientist" />
        <meta property="og:description" content="I am an Engineer and Data Scientist, founder of DreamStories.ai. I'm experienced in data stacks, AI models, and web development. Fluent in four languages, I bring a global perspective to data." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://calzavara.me/" />
        <meta property="og:image" itemprop="image" content='https://calzavara.me/phil400.webp' />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />
        <meta property="og:locale" content="en_US" />

        <link rel="apple-touch-icon" sizes="180x180" href={'/favico/apple-touch-icon.png'} />
        <link rel="icon" type="image/png" sizes="32x32" href={"/favico/favicon-32x32.png"} />
        <link rel="icon" type="image/png" sizes="16x16" href={"/favico/favicon-16x16.png"} />
      </Helmet>


      <Hero />
      <div className="container">
        <Intro />
      </div>
      <History />
      <Footer />
    </>
  )
}

export default IndexPage
