import * as React from "react"
import * as style from '../styles/hero.module.css'

export default function Hero() {
    return (
        <div className={style.hero}>
            <div className={style.heroContent}>
                <div className={style.heroText}>
                    <h1>Phil Calzavara</h1>
                    <h2>Engineer & Data Scientist</h2>
                </div>
                <div className={style.heroImg}>
                </div>
            </div>

        </div>
    )
}