import React from 'react'
import * as style from '../styles/contentBox.module.css'
import GetInTouch from './GetInTouch'

export default function History() {
  return (
    <>
      <div className={style.contentBox}>
        <h6>September 2023</h6>
        <p><a href='https://www.dreamstories.ai/' rel='noreferrer' target='_blank'><span style={{ textTransform: 'lowercase' }}>dreamstories.</span></a> is a unique platform for creating high-quality personalized stories, tailored to each individual and shipped directly to your home.</p>
        <p>As the solo founder, I build the AI models that make each story unique, complemented by leadership in marketing and product development.</p>
        <p>dreamstories. represents a novel intersection between art and data science, offering an immersive experience where you become the hero of your stories!</p>
        <div className='center'><a href='https://www.dreamstories.ai/' rel='noreferrer' target='_blank'><img src={'/dreamstories.webp'} width="500px" alt='A book created with dreamstories.ai' /></a></div>
      </div>
      <div className={style.contentBox}>
        <h6>March 2022</h6>
        <p><a href='https://www.uber.com/' rel='noreferrer' target='_blank'><span>At Uber</span></a>,
          I designed data-driven payment systems that ensured seamless transactions for millions of daily trips.</p>
        <p>I conducted experiments on products affecting over 20 million daily trips, contributing to more than $10 million in combined annual gross bookings.</p>
        <p>Furthermore, I developed advanced data pipelines to track user experiences with Uber's products, specifically on Uber Rides and Eats.</p>
        <div className='center'><img src={'/Uber.webp'} alt="A screenshot of Uber's app" /></div>
      </div>
      <div className={style.contentBox}>
        <h6>Sept 2019</h6>
        <p><a href='https://www.kencko.com/' rel='noreferrer' target='_blank'><span>At kencko</span></a>,
          as the first Data Scientist and early employee, I established the data architecture, created ETL pipelines and implemented BI tools.</p>
        <p>During my tenure, I offered strategic insights to the CEO and CBO, playing a role in raising $10 million in Series A funding, a milestone featured on <a href='https://techcrunch.com/2022/01/25/kencko-takes-in-new-capital-to-shake-up-how-we-consume-our-fruits-vegetables/' rel='noreferrer' target='_blank'>Tech Crunch</a>.</p>
        <p>I also designed and maintained the most strategic KPIs, measuring the acquisition and retention of subscribers.</p>
        <div className='center'><img src={'/kencko.webp'} alt='A bottle of kencko, instant smoothie' /></div>
      </div>
      <div className={style.contentBox}>
        <h6>Feb 2019</h6>
        <p><a href='https://www.geoblink.com/' rel='noreferrer' target='_blank'><span>At Geoblink</span></a>,
          I utilized geolocated datasets to build Machine Learning models predicting the unemployment rate and disposable income of Spanish households.</p>
        <p>Additionally, I built ETL pipelines on Airflow to fetch, transform, and store data, contributing to model development.</p>
        <p>This work earned me the highest grade at the Polytechnic University of Madrid and constituted a significant portion of my Master's thesis.</p>
        <div className='center'><img src={'/geoblink.webp'} alt='A screenshot of Geoblink, a location intelligence tool' /></div>
        {GetInTouch()}
      </div>
    </>
  )
}
