import * as React from "react"
import * as style from '../styles/footer.module.css'

export default function Footer() {
  return (
    <>
      <div className={style.footer}>
        <a href="https://www.linkedin.com/in/philcalzavara/" rel='noreferrer' target="_blank">
          <img src={'/linkedin.webp'} width="35px" alt="LinkedIn" />
        </a>

        <a href="https://github.com/philosss" rel='noreferrer' target="_blank">
          <img src={'/github.webp'} width="35px" alt="GitHub" />
        </a>
      </div>
    </>
  )
}
